
import { Provider } from 'mobx-react';
import React from 'react';
import Advertisement from './advertisement';
import ContactService from './contactService';
import dashboardStore from './dashboardStore';

import DeliveryProduct from './deliveryProduct';
import DialogNotice from './dialogNotice';
import styles from './index.less';
import Notice from './notice';
import RelatedProduct from './relatedProduct';
import SaleCount from './saleCount';
import ShortCut from './shortcut';
import SystemUpdate from './systemUpdate';
import TodoTask from './todoTask';

export class Dashboard extends React.Component {
  componentDidMount(): void {
    const { queryCommonInfo, queryBillNotice, queryOrderDelivery, queryTodoData, queryTopSale } = dashboardStore;
    queryCommonInfo();
    queryBillNotice();
    queryOrderDelivery();
    queryTodoData();
    queryTopSale();
  }

  render(): JSX.Element {
    return (
      <div className={styles.dashboard}>
        <Provider dashboardStore={dashboardStore}>
          <div className={`${styles.panel} ${styles.line1}`}>
            <TodoTask styles={styles}/>
            <ShortCut styles={styles}/>
            <Advertisement styles={styles}/>
          </div>
          <div className={`${styles.panel} ${styles.line2}`}>
            <DeliveryProduct styles={styles}/>
            <SaleCount styles={styles}/>
            <Notice styles={styles}/>
          </div>
          <div className={`${styles.panel} ${styles.line3}`}>
            <RelatedProduct styles={styles}/>
            <ContactService styles={styles}/>
            <SystemUpdate styles={styles}/>
          </div>
          <DialogNotice styles={styles}/>
        </Provider>
      </div>
    );
  }
}

export default Dashboard;
