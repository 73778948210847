import { inject, observer } from 'mobx-react';
import React from 'react';
import type { Props } from './interface';
import { getStaticResourceUrl } from 'egenie-common';
import { Popover } from 'antd';
import { api } from '../../../utils';

@inject('dashboardStore')
@observer
export default class relatedProduct extends React.Component<Props> {
  render(): JSX.Element {
    const { styles } = this.props;
    const imgStyle = {
      width: '30px',
      height: '30px',
    };
    const products: Array<{ img: string; id: string; name: string; content?; }> = [
      {
        img: 'offcialAccounts.png',
        id: 'offcialAccounts',
        name: '公众号',
        content: (
          <img
            className={styles.contentImg}
            src={`${api.oss}/images/offcialAccounts.jpg`}
          />
        ),
      },
      {
        img: 'baokuanMiniPrograme.png',
        id: 'baokuanMiniPrograme',
        name: '衫海精灵',
        content: (
          <img
            className={styles.contentImg}
            src={`${api.oss}/images/pushModelAppCode.png`}
          />
        ),
      },
      {
        img: 'youyouNumber.png',
        id: 'youyouNumber',
        name: '优优数字',
        content:
          (
            <img
              className={styles.contentImg}
              src={`${api.oss}/images/digitalCode.jpg`}
            />
          ),
      },
      {
        img: 'baokuanApp.png',
        id: 'baokuanApp',
        name: '衫海精',
        content: (
          <img
            className={styles.contentImg}
            src={`${api.oss}/images/baokuanAppCode.png`}
          />
        ),
      },
    ];
    const reference = [
      {
        name: 'PDA',
        // eslint-disable-next-line no-script-url
        // url: getStaticResourceUrl('pda/ejingling_8_0_10.apk'),
        url: 'https://cdss-wms.obs.cn-east-3.myhuaweicloud.com/erp-wms/pda/ejingling.apk',
        img: 'pda.png',
        download: 'PDA',
      },
      {
        name: '菜鸟组件下载',
        url: 'https://cdn-cloudprint.cainiao.com/waybill-print/cainiao-x-print/prod/win/cainiao-x-print-win-86.exe',
        img: 'caiNiao.png',
        download: '菜鸟组件',
      },
      {
        name: '精灵知识库',
        url: 'https://shanshu-tech.yuque.com/r/organizations/homepage',
        img: 'erpReference.png',
        download: false,
      },
      {
        name: '拼多多打印组件',
        url: 'https://meta.pinduoduo.com/api/one/app/v1/lateststable?appId=com.xunmeng.pddprint&platform=windows',
        img: 'pinDuoDuoPrint.png',
        download: '拼多多打印组件',
      },
      {
        name: '京东打印组件',
        url: 'https://prod-oms-app-cprt.jdwl.com/OpenCloudPrint/setup.exe',
        img: 'JDPrint.png',
        download: '京东打印组件',
      },
      {
        name: '抖店打印组件',
        url: 'https://logistics.douyinec.com/davinci/CloudPrintClient',
        img: 'DYPrint.png',
        download: '抖店打印组件',
      },
      {
        name: 'Chrome浏览器下载',
        url: 'https://www.google.cn/chrome/',
        img: 'chromeLogo.svg',
        download: false,
      },
    ];
    const productItem = (item) => (
      <div
        className={styles.item}
        key={item.id}
      >
        <img
          className={styles.img}
          src={`${api.oss}/images/${item.img}`}
        />
        <div className={styles.text}>
          <span>
            {item.name}
          </span>
        </div>
      </div>
    );
    return (
      <div className={styles.relatedProduct}>
        <div className={styles.header}>
          <div className={styles.title}>
            相关产品
          </div>
        </div>
        <div className={styles.content}/>
        <div className={styles.top}>
          {products.map((item) => (
            item.content
              ? (
                <Popover
                  content={item.content}
                  key={item.id}
                >
                  {productItem(item)}
                </Popover>
              )
              : productItem(item)
          ))}
        </div>
        <div className={styles.divideLine}/>
        <div className={styles.bottom}>
          {reference.map((item) => (
            <a
              className={styles.item}
              download={item.download}
              href={item.url}
              key={item.name}
              rel="noreferrer"
              target={!item.download ? '_blank' : undefined}
            >
              <img
                className={styles.img}
                src={`${api.oss}/images/${item.img}`}
              />
              <span>
                {item.name}
              </span>
            </a>
          ))}
        </div>
      </div>
    );
  }
}
