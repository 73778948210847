
import { inject, observer } from 'mobx-react';
import React from 'react';
import type { Props } from './interface';

@inject('dashboardStore')
@observer
export default class Notice extends React.Component<Props> {
  render(): JSX.Element {
    const { styles } = this.props;
    const { noticeList } = this.props.dashboardStore;
    return (
      <div className={styles.notice}>
        <div className={styles.header}>
          <div className={styles.title}>
            公告
          </div>

        </div>
        <div className={styles.content}>
          {noticeList.map((item) => (
            <div
              className={styles.item}
              key={item.id}
            >
              <div>
                <span className={`${styles.icon} icon-home_gg`}/>
                <span
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
              </div>
              <div className={styles.date}>
                {item.time}
              </div>
            </div>
          ))}
        </div>

      </div>
    );
  }
}
