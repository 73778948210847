import React from 'react';
import { api } from '../../../utils';

interface Style {
  [propName: string]: string;
}

interface Props {
  styles: Style;
}

export default class Advertisement extends React.Component<Props> {
  render(): JSX.Element {
    const { styles } = this.props;
    return (
      <div
        className={styles.advertisement}
      >
        <img
          className={styles.default}
          src={`${api.oss}/images/advertiseDefault.png`}
        />
      </div>
    );
  }
}
