import { Tabs } from 'antd';
import ReactECharts from 'echarts-for-react';
import { inject, observer } from 'mobx-react';
import React from 'react';
import type { Props } from './interface';

@inject('dashboardStore')
@observer
export default class SaleCount extends React.Component<Props> {
  render(): JSX.Element {
    const { styles } = this.props;
    const { getTopSaleInstance, topSaleData } = this.props.dashboardStore;
    const defaultStyle = { height: '260px' };

    const items = topSaleData.map((item) => ({
      label: item.productNo,
      key: item.productId,
      children: (
        <ReactECharts
          lazyUpdate
          notMerge
          option={item.option}
          ref={getTopSaleInstance}
          style={defaultStyle}
          theme="theme_name"
        />
      ),
    }));
    return (
      <div className={styles.saleCount}>
        <div className={styles.header}>
          <div className={styles.title}>
            销量TOP51
          </div>
        </div>
        <div id={styles.tabWraper}>
          <Tabs
            className={styles.tabs}
            defaultActiveKey="1"
            items={items}
            tabPosition="left"
          />
        </div>
      </div>
    );
  }
}

