import { Badge, Modal, Button, Progress, message } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';

import moment from 'moment';
import React from 'react';
import cart from '../../assets/images/cart.png';
import { api } from '../../utils';
import { LayoutGuide, LayoutStore } from '../../utils/layout';
import Dashboard from './dashboard/index';
import styles from './index.less';

const layoutStore = new LayoutStore();
layoutStore.handleInit();
interface IState {
  isXuanKuan: boolean;
  showRestDialog: boolean;
  logisticFailed: boolean;

  staticData?;
}
interface Opera {
  id: string | number;
  name: string;
  callback?: () => void;
}

// 导航 示例
export default class LayoutTest extends React.Component {
  public state: IState = {
    isXuanKuan: false,
    showRestDialog: false,
    logisticFailed: false,
    staticData: {},
  };

  componentDidMount(): void {
    request({ url: api.user }).then((res: { tenantType?; status: string; }) => {
      const isXuanKuan = res.tenantType.includes('100015');
      this.setState({ isXuanKuan });
    });
    request({
      method: 'POST',
      url: '/api/saleorder/rest/monitor/abnormalOrders',
    }).then((req: BaseData<{ logisticFailed: number; }>) => {
      this.setState({ logisticFailed: req.data.logisticFailed > 0 });
    });
  }

  public userInfoLeft = (): JSX.Element => {
    const { isXuanKuan } = this.state;
    return (
      <div>
        {isXuanKuan && (
          <span
            className={styles.shoppingCart}
            onClick={() => top.egenie.openTab('/egenie-ts-vogue/shoppingCart/index', 'shoppingCart', '进货车')}
          >
            <img
              src={cart}
              style={{
                width: 17,
                height: 17,
                marginBottom: 2,
                marginLeft: 0,
              }}
            />
            <span className={styles.cartText}>
              进货车
            </span>
            <span className={styles.splitIcon}>
              |
            </span>
          </span>
        )}
        <Badge
          dot={this.state.logisticFailed}
        >
          <span
            onClick={() => top.egenie.openTab('/egenie-ts-oms/monitor', 'monitor', '监控', 'd_inspect')}
            style={{
              color: '#1978ff',
              cursor: 'pointer',
            }}
          >
            监控
          </span>
        </Badge>

        <span className={styles.splitIcon}>
          |
        </span>
      </div>
    );
  };

  public userInfoRight = (): Opera[] => {
    return [
      {
        id: 'export',
        name: '导入导出任务中心',
        callback: () => top.egenie.openTab('/egenie-ts-baseinfo/exportList/index', 'export_task_center', '导入导出任务中心', 'zc_pfs'),
      },
      {
        id: 1,
        name: '余量查看',
        callback: () => {
          this.queryStatistics();
        },
      },
    ];
  };

  // 切换余量查看
  public toggleRestData = (flag: boolean, id?: number): void => {
    this.setState({ showRestDialog: flag });
    id && top.egenie.openTabId(id);
  };

  // 获取统计数据
  public queryStatistics = (): void => {
    request({
      method: 'post',
      url: api.tenantRechangeStatistics,
      data: {
        page: 1,
        pageSize: 20,
      },
    }).then((res: { data?; }) => {
      if (!res || !res.data) {
        message.error('获取余量数据失败');
        return;
      }
      this.setState({ staticData: res.data });
      this.toggleRestData(true);
    });
  };

  // 充值总天数
  public getTotalDay = (): number => {
    const { tenantRechargeVo } = this.state.staticData;
    if (!tenantRechargeVo) {
      return 0;
    }
    const { day, accountStartTime, overdrawDatetime } = tenantRechargeVo;
    if (day <= 0 || !accountStartTime || !overdrawDatetime) {
      return 0;
    }
    const start = moment(accountStartTime);
    const end = moment(overdrawDatetime);
    const totalDays = end.diff(start, 'days');
    return day / totalDays * 100;
  };

  public byCount = (): JSX.Element => {
    const { rechargeStatisticsVo } = this.state.staticData;
    if (!rechargeStatisticsVo) {
      return null;
    }
    return (
      <>
        <div className={styles.totalOrderNum}>
          总单量：
          <span>
            {rechargeStatisticsVo.quantity}
          </span>
        </div>
        <div className={styles.progressWrapper}>
          <Progress
            percent={(rechargeStatisticsVo.restQuantity / rechargeStatisticsVo.quantity) * 100}
            showInfo={false}
            strokeLinecap="square"
            strokeWidth={18}
            trailColor="#2075ff33"
          />
        </div>
        <div className={styles.restOrderNum}>
          剩余单量：
          <span>
            {rechargeStatisticsVo.restQuantity}
          </span>
        </div>
      </>
    );
  };

  public byYear = (): JSX.Element => {
    const { tenantRechargeVo } = this.state.staticData;
    if (!tenantRechargeVo) {
      return null;
    }
    const { day, accountStartTime, overdrawDatetime } = tenantRechargeVo;
    const days = day >= 0 ? day : 0;
    const isNeedCharge = day < 30;
    return (
      <>
        <div className={styles.date}>
          <span className={styles.dateBegin}>
            开始时间：
            {accountStartTime}
          </span>
          <span>
            结束时间：
            {overdrawDatetime}
          </span>
        </div>
        <div className={styles.count}>
          <Progress
            format={() => ''}
            percent={this.getTotalDay()}
            strokeColor={isNeedCharge ? '#E90F24' : '#1978ff'}
            strokeLinecap="square"
            strokeWidth={14}
            type="circle"
            width={240}
          />
          <div className={styles.countContent}>
            <div className={styles.title}>
              剩余使用天数
            </div>
            <div className={styles.days}>
              {days}
              天
            </div>
          </div>
        </div>
      </>
    );
  };

  public restModal = (): JSX.Element => {
    const { rechargeType } = this.state.staticData;

    return (
      <Modal
        centered
        className={styles.restModalWrapper}
        footer={rechargeType === 1 ? null : [
          <Button
            key="submit"
            onClick={this.toggleRestData.bind(this, false, 2696)}
            type="primary"
          >
            了解详情
          </Button>,
        ]}
        onCancel={this.toggleRestData.bind(this, false, null)}
        open={this.state.showRestDialog}
        title="单量使用统计"
        width={rechargeType === 1 ? 600 : 450}
      >
        {rechargeType === 1 ? this.byYear() : this.byCount()}

      </Modal>
    );
  };

  public getLogoImg = (): JSX.Element => {
    return (
      <img
        onDragStart={(e) => {
          e.preventDefault();
        }}
        src={`${api.oss}/images/erpLogo.png`}
        style={{ width: '100%' }}
      />
    );
  };

  render(): JSX.Element {
    const { showRestDialog } = this.state;
    return (
      <div>
        <LayoutGuide
          defaultDashboard={<Dashboard/>}
          haveDashboard={false}
          logoImg={this.getLogoImg()}
          store={layoutStore}
          userInfoLeft={this.userInfoLeft()}
          userInfoRight={this.userInfoRight()}
        />
        {showRestDialog && this.restModal()}

      </div>
    );
  }
}
