import { Modal, Table, Button, notification, Space } from 'antd';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import type { Props } from './interface';
import { ExclamationCircleOutlined } from '@ant-design/icons';

@inject('dashboardStore')
@observer
export default class DialogNotice extends React.Component<Props> {
  componentDidMount(): void {
    const { getShopExpireList, getRechargeStatistics } = this.props.dashboardStore;
    getShopExpireList();
    getRechargeStatistics();
  }

  public handleGoToDetail = (): void => {
    const { handleShortCutOpenTab } = this.props.dashboardStore;
    handleShortCutOpenTab({
      id: 616,
      name: '电子面单配置',
      url: '/page/wms/waybill/index.html',
      icon: 'icon-electronic_sheet',
    });
    notification.close('eletricSheet');
  };

  // 店铺过期授权页面
  public shopExpire = (): JSX.Element => {
    const { styles } = this.props;
    const { handleShopAgain, onClickServiceSubscriptionRebatesButton, handleAbleShop, toggleShopExpire, shopExpireList } = this.props.dashboardStore;
    const columns = [
      {
        title: '店铺名称',
        dataIndex: 'shopName',
        key: 'shopName',
      },
      {
        title: '平台类型',
        dataIndex: 'platformType',
        key: 'platformType',
        width: 90,
      },
      {
        title: '授权到期时间',
        dataIndex: 'expireTime',
        key: 'expireTime',
        width: 160,
      },
      {
        title: '过期时间',
        dataIndex: 'expireDay',
        key: 'expireDay',
        width: 80,
        render: (_, item) => {
          const isExpire = item.expireDay === '已过期';
          return (
            <span style={{ color: isExpire && 'red' }}>
              {isExpire ? '已过期' : item.expireDay}
            </span>
          );
        },
      },
      {
        title: '操作',
        dataIndex: 'address',
        render: (_, item) => {
          console.log('item......', item);
          return (
            <Space>
              {item.subscriptionServiceUrl ? (
                <a
                  onClick={() => item.subscriptionServiceUrl && window.open(item.subscriptionServiceUrl)}
                >
                  订购服务
                </a>
              ) : undefined}
              <a onClick={handleShopAgain.bind(this, item.shopId)}>
                重新授权
              </a>
              <a onClick={handleAbleShop.bind(this, item.shopId)}>
                禁用店铺
              </a>
            </Space>
          );
        },

      },
    ];

    return (
      <Modal
        centered
        className={styles.shopExpire}
        footer={null}
        getContainer={document.getElementById('dialogNotice')}
        onCancel={() => {
          toggleShopExpire(false);
        }}
        open
        title="店铺授权过期提醒"
        width={750}
      >
        <div className={styles.shopExpireTableDiv}>
          <div className={styles.tips}>
            <ExclamationCircleOutlined className={styles.tipsExclamationCircleOutlined}/>
            <div>
              <span>
                授权过期后将不能同步商品、订单、售后单等数据，也不能上传发货信息和获取电子面单
              </span>
              <span>
                部分平台授权需要先订购服务，服务订购成功后再点击【重新授权】，
                <a onClick={onClickServiceSubscriptionRebatesButton}>
                  点击查看
                </a>
                服务订购返款相关操作
              </span>
              <span>
                点击【禁用店铺】后，下次登陆将不会再显示该店铺的授权过期提醒
              </span>
            </div>
          </div>
          <Table
            className={styles.shopExpireTable}
            columns={columns}
            dataSource={shopExpireList}
            pagination={false}
            scroll={{ y: '60vh' }}
            size="middle"
          />
        </div>
      </Modal>
    );
  };

  // 电子面单
  public eletricSheet = (): void => {
    const { styles } = this.props;
    const { handleShortCutOpenTab, billNotice } = this.props.dashboardStore;
    const description = () => (
      <div className={styles.eletricDesc}>
        {billNotice.map((item) => (
          <div
            className={styles.item}
            key={item.branchCode}
          >
            <span>
              快递公司:
              {item.courierName}
            </span>
            <span className={styles.text}>
              网点编码:
              {item.branchCode}
            </span>
            <span>
              余额:
              {item.waybillCount}
            </span>
          </div>
        ))}
      </div>
    );
    const buttonGroups = () => (
      <div>
        <Button
          className={styles.eletricSetting}
          onClick={this.props?.dashboardStore?.onWithdrawal}
          type="default"
        >
          提醒设置
        </Button>
        <Button
          className={styles.eletricSetting}
          onClick={this.handleGoToDetail}
          type="default"
        >
          查看详情
        </Button>
      </div>
    );

    notification.open({
      message: '电子面单余额不足提醒',
      description: description(),
      btn: buttonGroups(),
      key: 'eletricSheet',
      duration: null,
      className: 'dashboardNotice',
      top: 64,
      onClick: () => {
        console.log('Notification Clicked!');
      },
    });
  };

  // 租户到期提醒
  public expireTips = (): void => {
    const { styles } = this.props;
    const { tenantRechargeExpire } = this.props.dashboardStore;
    const { notify, overdrawDatetime, startTime, rechargeType, recentDaysQuantity, restQuantity } = tenantRechargeExpire;

    const description = (): JSX.Element => {
      const nowDate = moment().format('YYYY-MM-DD');
      const endDate = moment(overdrawDatetime).format('YYYY-MM-DD');
      const diff = moment(endDate).diff(nowDate, 'day');

      // console.log('diff-------', diff, moment(1640503148000).format('YYYY-MM-DD'));

      return (
        <div>
          {
            rechargeType === 1 ? (
              <div>
                您的系统到期时间为
                <span style={{ color: '#ff0000' }}>
                  {endDate}
                </span>
                ，为了不影响正常使用，请及时续费充值。
              </div>
            ) : (
              <div>
                您的系统剩余单量为
                <span style={{ color: '#ff0000' }}>
                  {restQuantity}
                </span>
                单，近15天发货量为
                <span style={{ color: '#ff0000' }}>
                  {recentDaysQuantity}
                </span>
                ，为了不影响正常使用，请及时续费充值。
              </div>
            )
          }
        </div>
      );
    };

    const buttonGroups = (): JSX.Element => {
      const onClick = () => {
        window.top.egenie.openTab('/egenie-ts-baseinfo/systemOrderStatistics/index', 2696, '系统单量统计');
      };
      return (
        <div>
          <Button
            className={styles.eletricSetting}
            onClick={onClick}
            type="default"
          >
            查看详情
          </Button>
        </div>
      );
    };

    notify && notification.open({
      message: '系统单量预警',
      description: description(),
      btn: buttonGroups(),
      key: 'expireTips',
      duration: null,
      className: 'dashboardNotice',
      top: 64,
      onClick: () => {
        console.log('Notification Clicked!');
      },
    });
  };

  public renderModal = () => {
    const { wayBillRows, wayBillColumns, isPrewarn, onPrewarnOk, onPrewarnCancel } = this?.props?.dashboardStore;
    return (
      <Modal
        centered
        onCancel={onPrewarnCancel}
        onOk={onPrewarnOk}
        open={isPrewarn}
        title="电子面单余额预警设置"
        width={1000}
      >
        <Table
          columns={wayBillColumns}
          dataSource={wayBillRows}
          pagination={false}
          rowKey="_id"
          scroll={{
            y: 500,
            x: '100%',
          }}
          size="small"
        />
      </Modal>
    );
  };

  render(): JSX.Element {
    const { styles } = this.props;
    const { isShowShopExpire, billNotice, tenantRechargeExpire: { notify, overdrawDatetime, rechargeType, recentDaysQuantity, restQuantity }} = this.props.dashboardStore;

    let flag = false;
    if (notify) {
      if (rechargeType === 1) {
        const nowDate = moment().format('YYYY-MM-DD');
        const endDate = moment(overdrawDatetime).format('YYYY-MM-DD');
        const diff = moment(endDate).diff(nowDate, 'day');
        flag = Number(diff) < 15;
      } else if (rechargeType === 2) {
        flag = Number(recentDaysQuantity) > Number(restQuantity);
      }
    }
    return (
      <div className={styles.dialogNotice}>
        <div className={styles.shopExpireWrapper}>
          {isShowShopExpire && this.shopExpire()}
        </div>
        {this.renderModal()}
        {billNotice && billNotice.length > 0 && this.eletricSheet()}
        {flag && this.expireTips()}
      </div>
    );
  }
}
