
import { Tabs } from 'antd';
import ReactECharts from 'echarts-for-react';
import { inject, observer } from 'mobx-react';
import React from 'react';
import type { Props } from './interface';

@inject('dashboardStore')
@observer
export default class deleveryProduct extends React.Component<Props> {
  render(): JSX.Element {
    const { styles } = this.props;
    const { tabsData, defaultActiveKey, getDeliveryRefData } = this.props.dashboardStore;
    const defaultStyle = { height: '260px' };

    const items = tabsData.map((item) => ({
      label: item.name,
      children: item.showData ? (
        <ReactECharts
          lazyUpdate
          notMerge
          option={item.option}
          ref={getDeliveryRefData}
          style={defaultStyle}
          theme="theme_name"
        />
      ) : (
        <div className={styles.noDataTip}>
          暂无权限查看该数据
        </div>
      ),
      key: item.id,
    }));
    return (
      <div className={styles.deleveryProduct}>
        <Tabs
          className={styles.tabs}
          defaultActiveKey={defaultActiveKey}
          items={items}
        />
      </div>
    );
  }
}

