import { Row, Col, Modal } from 'antd';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { api } from '../../../utils';
import type { Props, TabParam } from './interface';

@inject('dashboardStore')
@observer
export default class TodoTask extends React.Component<Partial<Props>> {
  public handleTask = (item: TabParam): void => {
    window.top.egenie.openTab(item.url, item.id, item.name, item.icon);
  };

  public getDate = (): string => {
    // 结束日期 = 当前时间-前两天
    const currentTime = new Date().getTime();
    const surplusTime = currentTime - (24 * 2 * 60 * 60 * 1000);
    return moment(surplusTime).format('YYYY-MM-DD HH:mm:ss');
  };

  render(): JSX.Element {
    const { styles } = this.props;
    const { todoData } = this.props.dashboardStore;
    const todoList = [
      {
        name: '订单处理',
        count: todoData.waitCheck,
        subName: '待审核',
        id: 505,
        url: '/ejl-erp-oms/order?is_checked-3-1=false',
        icon: 'icon_ddcl',
      },
      {
        name: '发货单',
        subName: '待打印',
        count: todoData.waitPrint,
        id: 640,
        url: '/ejl-erp-wms/bill/wmsOrder?courier_print_mark_state-4-1=1',
        icon: 'icon_fhd',
      },
      {
        name: '发货单3.0',
        subName: '待验货',
        count: todoData.waitReceive,
        id: 60104,
        url: '/egenie-ts-wms/wmsorder/index?is_lock-3-1=false&courier_print_mark_state-4-1=2',
        icon: 'icon_fhd',
      },
      {
        name: '订单处理',
        subName: '超过2天未发货',
        count: todoData.unPrintBefore2Day,
        icon: 'icon_ddcl',
        id: 505,
        url: `/ejl-erp-oms/order?dateType=sale_order_status.pay_time&courier_print_mark_state-4-14=1&dateValueEnd=${this.getDate()}`,

      },
      {
        id: 'monitor',
        name: '监控',
        subName: '上次发货失败',
        count: todoData.undelivered,
        icon: 'd_inspect',
        url: '/egenie-ts-oms/monitor',
      },
      {
        name: '订单处理',
        subName: '采购完成未打印',
        count: todoData.purchasedUnPrint,
        id: 505,
        icon: 'icon_ddcl',
        url: '/egenie-ts-oms/?purchase_state-4-13=2&courier_print_mark_state-4-14=1&is_suspended-3-1=false',
      },
    ];
    return (
      <div
        className={styles.todotask}
      >
        <img
          className={styles.imgbig}
          src={`${api.oss}/images/blueBlock.png`}
        />
        <div className={styles.content}>
          <div className={styles.title}>
            <span className={`${styles.icon} icon-home_dbsx`}/>
            <span className={styles.titleText}>
              待办事项
            </span>
          </div>
          <Row className={styles.list}>
            {todoList.map((item) => (
              <Col
                className={styles.item}
                key={item.subName}
                onClick={this.handleTask.bind(this, item)}
                span={6}
              >
                <span className={styles.count}>
                  {item.count || 0}
                </span>
                <span className={styles.name}>
                  {item.subName}
                </span>
              </Col>
            ))}
          </Row>
        </div>

      </div>
    );
  }
}
