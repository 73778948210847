
import { inject, observer } from 'mobx-react';
import React from 'react';
import { api } from '../../../utils';
import type { Props } from './interface';

@inject('dashboardStore')
@observer
export default class systemUpdate extends React.Component<Props> {
  public handleToSystemUpdate = (): void => {
    top.egenie.openTab('/egenie-erp-home/announcement?type=2&platformType=1', 'systemUpdate', '系统更新');
  };

  render(): JSX.Element {
    const { styles } = this.props;
    const { systemUpdateData } = this.props.dashboardStore;
    return (
      <div className={styles.systemUpdate}>
        <div className={styles.header}>
          <div className={styles.title}>
            系统更新
          </div>
          <div
            className={styles.more}
            onClick={this.handleToSystemUpdate}
          >
            <span>
              更多
            </span>
            <span className="icon-home_gd"/>
          </div>
        </div>
        <div className={styles.content}>

          <div
            className={styles.item}
          >
            <div className={styles.title}>
              <img
                className={styles.img}
                src={`${api.oss}/images/news.png`}
              />
              <span>
                {systemUpdateData.title}
              </span>
            </div>
            <div className={styles.content}>
              {systemUpdateData.content.map((item) => (
                <div
                  className={styles.text}
                  dangerouslySetInnerHTML={{ __html: item }}
                  key={item}
                />
              ))}
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}
